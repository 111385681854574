body {
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  user-select: none; /* Standard */
}

@keyframes moveGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.intro-mob {
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background: radial-gradient(circle, #d5ebff, #f8f7ff, #e9e5ff);
  background-size: 200% 200%;
  animation: moveGradient 6s ease infinite;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-14xl) 0 0;
  gap: 0; /* Remove gap to avoid space between sections */
  line-height: normal;
  letter-spacing: normal;
}

.welcome-section,
.logo-section,
.short-note-section,
.bottom-section {
  width: 100%;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.welcome-section {
  height: 20%;
}

.logo-section {
  height: 30%;
}

.short-note-section {
  height: 30%;
}

.bottom-section {
  height: 20%;
}

#get-started-btn {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

#lemon-img {
  width: 150px;
  height: 150px;
}
