.menu-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Always 3 columns */
  gap: 5px; /* Space between the columns */
  white-space: nowrap;
  background-color: white;
}

.menu-container > * {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 78px;
  background-color: white;
}

.menu-container > *:hover {
  background-color: #e9ecef;
  cursor: pointer;
}
