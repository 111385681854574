.mob-dashboard-container {
  width: 100%;
  min-height: 100vh;
  background-color: #eef3f4;
}

#header-section {
  position: fixed;
  height: 60px;
  width: 100%;
  z-index: 10;
  background-color: transparent; /* Initial transparent background */
  transition: background-color 0.3s ease; /* Smooth transition */
}

#header-section.opaque {
  background-color: #eef3f4; /* Opaque white background on scroll */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Optional shadow effect */
}

#wallet-section {
  position: absolute;
  top: 70px;
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#menu-section {
  position: absolute;
  top: 280px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#notif-section {
  position: absolute;
  top: 460px;
  height: 250px;
  width: 100%;
  padding: 10px;
}

#footer-section {
  position: fixed;
  height: 12%;
  width: 100%;
  bottom: 10px;
}

.custom-swal-popup {
  width: 300px;
}

.custom-swal-icon {
  font-size: 10px; /* Adjust size as needed */
  width: 40px;
  height: 40px;
}
