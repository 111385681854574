.horizontal-align {
  display: flex;
  align-items: center; /* Centers the items vertically */
}

.moon-and-stars {
  margin-left: 8px; /* Adjust spacing as needed */
  width: 18px;
  height: 18px;
  padding: 2px;
  border-radius: 50%;
  margin-bottom: 2px;
}

.header-container,
#header-container {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
  height: 100%;
  justify-content: space-between; /* Align items across the container */
}

.header-icons {
  width: 40px;
  height: 40px;
  padding: 2px;
  /* background: rgb(240, 240, 240);
  border-radius: 50%; */
  cursor: pointer;
  margin-bottom: 2px;
}

#greetings {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: small;
  text-align: center; /* Centers text horizontally */
}
