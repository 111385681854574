.menu-item {
  width: 64px;
  height: 64px;
  border: 1px solid transparent; /* Changed from `border: 1px;` */
  border-radius: 10px;
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center;
  justify-content: center;
  margin: 1px;
  padding: 5px;
}

.menu-icon {
  width: 48px;
  height: 48px;
  padding: 5px;
  background: rgb(240, 240, 240);
  border-radius: 50%;
  margin-bottom: 2px;
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.menu-label {
  font-size: 10px;
  text-align: center; /* Center the text */
}
